'use client';

import { usePathname } from 'next/navigation';
import Link from '@/components/Link';
import IconBox from '@/components/IconBox';
import Icon from '@/components/Icon';
import { useEffect, useState } from 'react';

function DocsLink({
  pathname,
  href,
  icon,
  color,
  title,
  description,
  open,
  current,
  items,
}) {
  return (
    <>
      <Link
        href={href}
        className={`group flex gap-3 items-center ${
          current
            ? 'opacity-100 text-1'
            : 'opacity-90 hover:opacity-100 text-3 hover:text-1 transition-colors'
        } py-1 cursor-pointer`}
        title={description}>
        {icon && <IconBox size="sm" color={color} icon={icon} />}
        <span className="block max-w-[15rem] 2xl:max-w-[20rem] whitespace-nowrap text-ellipsis overflow-hidden text-sm leading-5">
          {title}
        </span>
      </Link>
      {open && items ? (
        <ul className="pl-3 mb-2">
          {items.map(item => (
            <li key={item.href}>
              <DocsLink
                {...item}
                pathname={pathname}
                open={Boolean(pathname.startsWith(item.href))}
                current={Boolean(pathname === item.href)}
              />
            </li>
          ))}
        </ul>
      ) : null}
    </>
  );
}

export default function DocsMenu({ docsMenuItems }) {
  const pathname = usePathname();
  const [activeSections, setActiveSections] = useState([]);

  useEffect(() => {
    let newActiveSections = [];
    docsMenuItems
      .filter(section => section.menuItem)
      .forEach(section => {
        if (pathname === '/docs') {
          newActiveSections.push(section.id);
        } else if (section.items.some(item => pathname.startsWith(item.href))) {
          newActiveSections.push(section.id);
        }
      });
    setActiveSections(newActiveSections);
  }, [docsMenuItems, pathname]);

  function updateActiveSections({ active, sectionId }) {
    if (active.includes(sectionId)) {
      setActiveSections(active.filter(id => id !== sectionId));
    } else {
      setActiveSections([...active, sectionId]);
    }
  }

  return (
    <ul className={`flex flex-col items-stretch justify-start pb-12`}>
      {docsMenuItems
        .filter(section => section.menuItem)
        .map(section => {
          return (
            <li key={section.id}>
              <button
                className="w-full flex items-center justify-between pt-4 pb-2 text-2 hover:text-1 transition-colors"
                onClick={() =>
                  updateActiveSections({
                    active: activeSections,
                    sectionId: section.id,
                  })
                }>
                {section.title && (
                  <span className="text-base/[1.25em] font-code uppercase">
                    {section.title}
                  </span>
                )}
                <Icon
                  icon={'chevron_right'}
                  className={`opacity-50 w-4 h-4 ${
                    activeSections.includes(section.id) ? 'rotate-90' : ''
                  }`}
                />
              </button>
              {activeSections.includes(section.id) ? (
                <ul>
                  {section.items.map(item => (
                    <li key={item.href}>
                      <DocsLink
                        href={item.href}
                        icon={item.icon}
                        color={item.color || 'purple'}
                        title={item.title}
                        description={item.description}
                        open={Boolean(pathname.startsWith(item.href))}
                        current={Boolean(pathname === item.href)}
                        pathname={pathname}
                        items={item.items}
                      />
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          );
        })}
    </ul>
  );
}
